import * as THREE from 'three';
import Component from '../../Component'


export default class Sky extends Component{
    constructor(scene, skyTexture){
        super();
        this.scene = scene;
        this.name = 'Sky';
        this.texture = skyTexture;
    }

    Initialize(){
        const ambientlight = new THREE.AmbientLight(0x6688cc)
        this.scene.add(ambientlight)

        const fillLight1 = new THREE.DirectionalLight(0xf9d71c, 4)
        fillLight1.position.set(- 1, 1, 2)
        this.scene.add(fillLight1)

        const fillLight2 = new THREE.DirectionalLight(0xf9d71c, 0.4)
        fillLight2.position.set(0, - 1, 0)
        this.scene.add(fillLight2)

        const directionalLight = new THREE.DirectionalLight(0xf9d71c, 1.2)
        directionalLight.position.set(- 5, 25, - 1)
        directionalLight.castShadow = true
        directionalLight.shadow.camera.near = 0.01
        directionalLight.shadow.camera.far = 500
        directionalLight.shadow.camera.right = 30
        directionalLight.shadow.camera.left = - 30
        directionalLight.shadow.camera.top = 30
        directionalLight.shadow.camera.bottom = - 30
        directionalLight.shadow.mapSize.width = 1024
        directionalLight.shadow.mapSize.height = 1024
        directionalLight.shadow.radius = 4
        directionalLight.shadow.bias = - 0.00006
        this.scene.add(directionalLight)

        this.scene.background = new THREE.Color(0x88ccff)
    }
}