import './style.css'
 import * as THREE from 'three'
 import {AmmoHelper, Ammo, createConvexHullShape} from './AmmoLib'
 import EntityManager from './EntityManager'
 import Entity from './Entity'
 import Sky from './entities/Sky/Sky2'
 import LevelSetup from './entities/Level/LevelSetup'
 import PlayerControls from './entities/Player/PlayerControls'
 import PlayerPhysics from './entities/Player/PlayerPhysics'
 import {  FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
 import {  GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
 import {  OBJLoader } from 'three/examples/jsm/loaders/OBJLoader'
 import {  SkeletonUtils } from 'three/examples/jsm/utils/SkeletonUtils'
 import NpcCharacterController from './entities/NPC/CharacterController'
 import Input from './Input'

 import level from '../static/assets/level.glb'
 import navmesh from '../static/assets/navmesh.obj'
 
 //AK47 Model and textures
 import ak47 from '../static/assets/guns/ak47/ak47.glb'
 import muzzleFlash from '../static/assets/muzzle_flash.glb'
 //Shot sound
 import ak47Shot from '../static/assets/sounds/ak47_shot.wav'
 
 //Ammo box
 import ammobox from '../static/assets/ammo/AmmoBox.fbx'
 import ammoboxTexD from '../static/assets/ammo/AmmoBox_D.tga.png'
 import ammoboxTexN from '../static/assets/ammo/AmmoBox_N.tga.png'
 import ammoboxTexM from '../static/assets/ammo/AmmoBox_M.tga.png'
 import ammoboxTexR from '../static/assets/ammo/AmmoBox_R.tga.png'
 import ammoboxTexAO from '../static/assets/ammo/AmmoBox_AO.tga.png'
 
 //Bullet Decal
 import decalColor from '../static/assets/decals/decal_c.jpg'
 import decalNormal from '../static/assets/decals/decal_n.jpg'
 import decalAlpha from '../static/assets/decals/decal_a.jpg'
 
 //Sky
 import skyTex from '../static/assets/sky.jpg'
 
 import DebugDrawer from './DebugDrawer'
 import Navmesh from './entities/Level/Navmesh'
 import AttackTrigger from './entities/NPC/AttackTrigger'
 import DirectionDebug from './entities/NPC/DirectionDebug'
 import CharacterCollision from './entities/NPC/CharacterCollision'
 import Weapon from './entities/Player/Weapon'
 import UIManager from './entities/UI/UIManager'
 import AmmoBox from './entities/AmmoBox/AmmoBox'
 import LevelBulletDecals from './entities/Level/BulletDecals'
 import PlayerHealth from './entities/Player/PlayerHealth'
 import { Howl, Howler } from 'howler'
 
 class FPSGameApp{
 
   constructor(){
     this.lastFrameTime = null;
     this.assets = {};
     this.animFrameId = 0;
 
     AmmoHelper.Init(()=>{this.Init();});
   }
 
   Init(){
     this.LoadAssets();
     this.SetupGraphics();
     this.SetupStartButton();
   }
 
   SetupGraphics(){
     this.scene = new THREE.Scene();
     this.renderer = new THREE.WebGLRenderer({antialias: true});
     this.renderer.shadowMap.enabled = true;
     this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
 
     this.renderer.toneMapping = THREE.ReinhardToneMapping;
     this.renderer.toneMappingExposure = 1;
     this.renderer.outputEncoding = THREE.sRGBEncoding;
 
     this.camera = new THREE.PerspectiveCamera();
     this.camera.near = 0.01;
 
     // create an AudioListener and add it to the camera
     this.listener = new THREE.AudioListener();
     this.camera.add( this.listener );
 
     // renderer
     this.renderer.setPixelRatio(window.devicePixelRatio);
 
     this.WindowResizeHanlder();
     window.addEventListener('resize', this.WindowResizeHanlder);
 
     document.body.appendChild( this.renderer.domElement );

   }
 
   SetupPhysics() {
     // Physics configuration
     const collisionConfiguration = new Ammo.btDefaultCollisionConfiguration();
     const dispatcher = new Ammo.btCollisionDispatcher( collisionConfiguration );
     const broadphase = new Ammo.btDbvtBroadphase();
     const solver = new Ammo.btSequentialImpulseConstraintSolver();
     this.physicsWorld = new Ammo.btDiscreteDynamicsWorld( dispatcher, broadphase, solver, collisionConfiguration );
     this.physicsWorld.setGravity( new Ammo.btVector3( 0.0, -9.81, 0.0 ) );
     const fp = Ammo.addFunction(this.PhysicsUpdate);
     this.physicsWorld.setInternalTickCallback(fp);
     this.physicsWorld.getBroadphase().getOverlappingPairCache().setInternalGhostPairCallback(new Ammo.btGhostPairCallback());
 
     //Physics debug drawer
     this.debugDrawer = new DebugDrawer(this.scene, this.physicsWorld);
     this.debugDrawer.enable();
   }
 
   SetAnim(name, obj){
     const clip = obj.animations[0];
   }
 
   PromiseProgress(proms, progress_cb){
     let d = 0;
     progress_cb(0);
     for (const p of proms) {
       p.then(()=> {    
         d++;
         progress_cb( (d / proms.length) * 100 );
       });
     }
     return Promise.all(proms);
   }
 
   AddAsset(asset, loader, name){
     return loader.loadAsync(asset).then( result => {
       this.assets[name] = result;
     });
   }

   OnProgress(p){
     const progressbar = document.getElementById('progress');
     progressbar.style.width = `${p}%`;
   }
 
   HideProgress(){
     this.OnProgress(0);
   }
 
   SetupStartButton(){
     document.getElementById('start_game').addEventListener('click', this.StartGame);
   }
 
   ShowMenu(visible=true){
     document.getElementById('start_game').style.visibility = visible?'visible':'hidden';
   }
 
   async LoadAssets(){
     const gltfLoader = new GLTFLoader();
     const fbxLoader = new FBXLoader();
     const objLoader = new OBJLoader();
     const audioLoader = new THREE.AudioLoader();
     const texLoader = new THREE.TextureLoader();
     const promises = [];
     // audio files are too huge, we dont download them, but we prepare them for streaming
     this.audio = new Howl({
        src: [`/audio/beach.wav`],
        html5: true
      })
    
 
     //Level
     promises.push(this.AddAsset(level, gltfLoader, "level"));
     promises.push(this.AddAsset(navmesh, objLoader, "navmesh"));

     //AK47
     promises.push(this.AddAsset(ak47, gltfLoader, "ak47"));
     promises.push(this.AddAsset(muzzleFlash, gltfLoader, "muzzleFlash"));
     promises.push(this.AddAsset(ak47Shot, audioLoader, "ak47Shot"));
     //Ammo box
     promises.push(this.AddAsset(ammobox, fbxLoader, "ammobox"));
     promises.push(this.AddAsset(ammoboxTexD, texLoader, "ammoboxTexD"));
     promises.push(this.AddAsset(ammoboxTexN, texLoader, "ammoboxTexN"));
     promises.push(this.AddAsset(ammoboxTexM, texLoader, "ammoboxTexM"));
     promises.push(this.AddAsset(ammoboxTexR, texLoader, "ammoboxTexR"));
     promises.push(this.AddAsset(ammoboxTexAO, texLoader, "ammoboxTexAO"));
     //Decal
     promises.push(this.AddAsset(decalColor, texLoader, "decalColor"));
     promises.push(this.AddAsset(decalNormal, texLoader, "decalNormal"));
     promises.push(this.AddAsset(decalAlpha, texLoader, "decalAlpha"));
 
     promises.push(this.AddAsset(skyTex, texLoader, "skyTex"));
 
     await this.PromiseProgress(promises, this.OnProgress);
 
     this.assets['level'] = this.assets['level'].scene;
     this.assets['muzzleFlash'] = this.assets['muzzleFlash'].scene;
 
 
     this.assets['ak47'].scene.animations = this.assets['ak47'].animations;
     
     //Set ammo box textures and other props
     this.assets['ammobox'].scale.set(0.01, 0.01, 0.01);
     this.assets['ammobox'].traverse(child =>{
       child.castShadow = true;
       child.receiveShadow = true;
       
       child.material = new THREE.MeshStandardMaterial({
         map: this.assets['ammoboxTexD'],
         aoMap: this.assets['ammoboxTexAO'],
         normalMap: this.assets['ammoboxTexN'],
         metalness: 1,
         metalnessMap: this.assets['ammoboxTexM'],
         roughnessMap: this.assets['ammoboxTexR'],
         color: new THREE.Color(0.4, 0.4, 0.4)
       });
       
     });
 
     this.assets['ammoboxShape'] = createConvexHullShape(this.assets['ammobox']);
 
     this.HideProgress();
     this.ShowMenu();
   }
 
   EntitySetup(){
     this.entityManager = new EntityManager();
 
     const levelEntity = new Entity();
     levelEntity.SetName('Level');
     levelEntity.AddComponent(new LevelSetup(this.assets['level'], this.scene, this.physicsWorld));
     levelEntity.AddComponent(new Navmesh(this.scene, this.assets['navmesh']));
     levelEntity.AddComponent(new LevelBulletDecals(this.scene, this.assets['decalColor'], this.assets['decalNormal'], this.assets['decalAlpha']));
     this.entityManager.Add(levelEntity);
 
     const skyEntity = new Entity();
     skyEntity.SetName("Sky");
     skyEntity.AddComponent(new Sky(this.scene, this.assets['skyTex']));
     this.entityManager.Add(skyEntity);
 
     const playerEntity = new Entity();
     playerEntity.SetName("Player");
     playerEntity.AddComponent(new PlayerPhysics(this.physicsWorld, Ammo));
     playerEntity.AddComponent(new PlayerControls(this.camera, this.scene));
     playerEntity.AddComponent(new Weapon(this.camera, this.assets['ak47'].scene, this.assets['muzzleFlash'], this.physicsWorld, this.assets['ak47Shot'], this.listener ));
     playerEntity.AddComponent(new PlayerHealth());
     playerEntity.SetPosition(new THREE.Vector3(-28.55, 5.85, 40.3));

     this.entityManager.Add(playerEntity);

     const uimanagerEntity = new Entity();
     uimanagerEntity.SetName("UIManager");
     uimanagerEntity.AddComponent(new UIManager());
     this.entityManager.Add(uimanagerEntity);
 
     const ammoLocations = [
        [-15.24843978881836, 1.2,  51.2828254699707],
        [-15.85, 1.2, 2.6],
        [5.85, 1.2, 2.6],
        [15.85, 1.2, 2.6],
        [25.85, 1.2, 2.6],
        [35.85, 1.2, 2.6],
        [45.85, 1.2, 2.6],
        [55.85, 1.2, 2.6],
        [65.85, 1.2, 2.6]
     ];
 
     ammoLocations.forEach((loc, i) => {
       const box = new Entity();
       box.SetName(`AmmoBox${i}`);
       box.AddComponent(new AmmoBox(this.scene, this.assets['ammobox'].clone(), this.assets['ammoboxShape'], this.physicsWorld));
       box.SetPosition(new THREE.Vector3(loc[0], loc[1], loc[2]));
       this.entityManager.Add(box);
     });
 
     this.entityManager.EndSetup();
 
     this.scene.add(this.camera);
     this.animFrameId = window.requestAnimationFrame(this.OnAnimationFrameHandler);
   }
 
   StartGame = ()=>{
    document.getElementById('menu').style.display = "none";

     window.cancelAnimationFrame(this.animFrameId);
     Input.ClearEventListners();
 
     //Create entities and physics
     this.scene.clear();
     this.SetupPhysics();
     this.EntitySetup();
     this.audio.play();
     this.audio.loop(true);
   }
 
   // resize
   WindowResizeHanlder = () => { 
     const { innerHeight, innerWidth } = window;
     this.renderer.setSize(innerWidth, innerHeight);
     this.camera.aspect = innerWidth / innerHeight;
     this.camera.updateProjectionMatrix();
   }
 
   // render loop
   OnAnimationFrameHandler = (t) => {
     if(this.lastFrameTime===null){
       this.lastFrameTime = t;
     }
 
     const delta = t-this.lastFrameTime;
     let timeElapsed = Math.min(1.0 / 30.0, delta * 0.001);
     this.Step(timeElapsed);
     this.lastFrameTime = t;

     this.animFrameId = window.requestAnimationFrame(this.OnAnimationFrameHandler);
   }
 
   PhysicsUpdate = (world, timeStep)=>{
     this.entityManager.PhysicsUpdate(world, timeStep);
   }
 
   Step(elapsedTime){
     this.physicsWorld.stepSimulation( elapsedTime, 10 );
     this.debugDrawer.update();
 
     this.entityManager.Update(elapsedTime);
 
     this.renderer.render(this.scene, this.camera);
   }
 
 }
 
 let _APP = null;
 window.addEventListener('DOMContentLoaded', () => {
   _APP = new FPSGameApp();
 });